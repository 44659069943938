import React, { Component} from 'react';
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Img from "gatsby-image"

class AuthorTemplate extends Component {
  render() {
    const authorData = this.props.data.contentfulAuthor;

    console.log(this.props.data)

    return (
      <Layout>
          <SEO title={authorData.name + ' - Krasniy Report'} keywords={[`gatsby`, `application`, `react`]} />

          <h1>{authorData.name}</h1>
          <Img fluid={authorData.photo.fluid} style={{'maxWidth':'100px'}}/>
          <p>{authorData.title} at {authorData.company}</p>

          {authorData && authorData.twitter ? (<a href={"https://twitter.com/"+authorData.twitter} target="_blank" rel="noopener noreferrer">Twitter</a> ) : ''}
          
      </Layout>
    )
  }
}


export default AuthorTemplate


export const authorQuery = graphql`
query authorQuery($slug: String!){
  contentfulAuthor(slug: {eq: $slug}) {
          id
          name
          title
          slug
          company
          photo
            {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid
            }
            }
          shortBio {
            shortBio
          }
          twitter
          email
       
    }
    allContentfulBlogPost(filter: { author: { slug:  {eq: $slug}}}){
      edges {
        node {
          id
          title
          author {
            id
            slug
          }
        }
      }
    }        
  }
`




